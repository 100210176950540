.basket-top{
  border-radius: 5px;
  background-color: $gray-line;
  margin-bottom: 1rem;
}
.basket-item{
  margin-top: 1rem;
  display: flex;
  align-items: center;
  &__image-block{
    display: flex;
    align-items: center;
  }
  &__image-wrapper{
    border-radius: 10px;
    overflow: hidden;
    width: 75px;
    height: 75px;
    border: 2px solid $gray-line;
  }
  &__text-wrapper{
    padding: 1rem;
  }
  &__image{
    height: 100%;
    width: auto;
  }
  &__count-wrapper{
    background-color: $gray-line;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &__count-button{
    cursor: pointer;
  }
  &__title{
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $blue;
  }
}
.basket-empty{
  background-color: $gray-line;
  padding: 2rem;
  height: 100%;
  flex-direction: column !important;
  &__image-wrapper{
    width: 150px;
  }
}
.basket-total{
  &__wrapper{
    background-color: $gray-line;
    border-radius: 5px;
  }
}