@import '../variables';

.header {
  border-bottom: 1px solid $gray-line;
  padding: 5px 0;
  background-color: white !important;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: flex;

    img {
      margin-right: 15px;
    }

    h1 {
      color: $black;
      font-size: 40px;
      //letter-spacing: 1%;
      text-transform: uppercase;
      font-weight: 800;
      line-height: initial;
    }

    p {
      color: $blue;
      font-size: 16px;
      letter-spacing: 5px;
      line-height: 8px;
    }
  }
  &__button{
    color: $blue;
  }
  &__cart{
    color: $blue;
  }
}
@media screen and (max-width: 900px) {
  .header__logo {
    display: block !important;
    img{
      width: 50px !important;
    }
  }
}