@import "../variables";

.print{
  width: 100%;
  height: 100%;
  &__load-space{
    border: 2px dashed $blue;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &__camera-icon{
    color: $gray-line;
  }
  &__image-cart{
    height: 220px;
    position: relative;

    &:hover{
      .print__image-cart__delete-button{
        opacity: 1 !important;
      }
    }
    &__delete-button{
      transition: opacity 0.1s ease-in-out !important;
      position: absolute !important;
      right: 5px;
      top: 5px;
      opacity: 0 !important;
    }
    &__edit-button{
      transition: opacity 0.1s ease-in-out !important;
      right: 5px;
      &:hover{
        opacity: 1;
      }
    }
  }
  &__image-cart-skeleton>* {
    visibility: visible !important;
  }
}
.modal__form{
  max-width: 400px !important;
  width: 90% !important;
  border: none !important;
  padding: 1rem !important;
}

@media screen and (max-width: 400px) {
  .modal__form-picture{
    max-height: 156px;
  }
}

